<template>
  <section class="py-16 bg-dark">
    <div class="container">
      <h1 class="font-bold text-2xl sm:text-4xl lg:text-6xl text-white mt-16">
        All Projects
      </h1>
      <ul class="mt-10">
        <li
          v-for="(project, index) in projects"
          :key="index"
          class="flex items-start mb-8"
        >
          <div class="bg-dark-brown shadow">
            <div
              class="h-20 w-20 sm:h-32 sm:w-32 flex items-center justify-center"
            >
              <span
                v-if="project.image === ''"
                class="text-5xl font-bold text-white uppercase"
              >
                {{ project.title.charAt(0) }}
              </span>
              <img
                v-else
                class="h-16 sm:h-20"
                :src="require(`@/assets/images/${project.image}`)"
                :alt="project.title"
              />
            </div>
          </div>
          <div class="ml-8">
            <h2 class="text-white font-bold text-lg">{{ project.title }}</h2>
            <p class="text-grey mt-1 leading-relaxed max-w-md mb-2">
              {{ project.content }}
            </p>
            <a
              v-if="project.link !== ''"
              class="text-primary"
              :href="project.link"
              target="_blank"
            >
              View Site
            </a>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProjectListSection',
  data() {
    return {
      projects: [
        {
          title: 'NovaCare',
          content:
            'Automated Voice (IVR)  & SMS based messaging engine for registration and delivery of messages, maternal care information, business-critical information either real-time or stage-based.',
          image: '',
          link: ''
        },
        {
          title: 'Hellomama - Bot',
          content:
            'Receive age and stage based maternal health care tips in your preferred language.',
          image: '',
          link: 'https://hellomama.africa/'
        },
        {
          title: 'Lagos Learn - Bot',
          content:
            'Lagos State government initiative (powered by OEQA) to distribute free learning resources via telegram bot during COVID-19 pandemic.',
          image: '',
          link: 'https://t.me/lagoslearnstogetherbot'
        },
        {
          title: 'Sendy',
          content: 'Automate recurring airtime for business, project teams.',
          image: 'project_logo_2.png',
          link: 'https://sendy.tinylabs.app/'
        },
        {
          title: 'Getchange',
          content: 'Accept change as airtime or mobile money.',
          image: 'project_logo_3.png',
          link: 'https://getchange.tinylabs.app/'
        },
        {
          title: 'Ryddl',
          content: 'Take short quizzes, learn new things and get rewarded.',
          image: 'project_logo_4.png',
          link: 'http://ryddl.com/'
        },
        {
          title: 'Gateway Mobile App',
          content: 'Stay connected with Gateway Int’l Church on the go.',
          image: 'gic.png',
          link:
            'https://play.google.com/store/apps/details?id=org.gicfamily.gateway_connect&hl=en&gl=US'
        },
        {
          title: 'FoodJaar',
          content:
            'Ecommerce app for a fresh farm produce and grocery company.',
          image: 'foodjaar_color.png',
          link: 'https://foodjaar.com/'
        },
        {
          title: 'FruityLife',
          content: 'Ecommerce app for healthy food options.',
          image: 'project_logo_1.png',
          link: 'https://fruitylife.com.ng/'
        },
        {
          title: 'Jbloodmatch - Chatbot',
          content:
            'Connects non-remunerated voluntary blood donors to matching recipients at the point of need.',
          image: 'jblood_match.png',
          link: 'https://jbloodmatch.org/'
        }
      ]
    }
  }
}
</script>
