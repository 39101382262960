<template>
  <div>
    <nav-header />
    <project-list-section />
    <nav-footer />
  </div>
</template>

<script>
import NavHeader from '@/components/navigation/NavHeader'
import NavFooter from '@/components/navigation/NavFooter'
import ProjectListSection from '@/components/sections/ProjectListSection'

export default {
  name: 'Projects',
  components: { NavHeader, NavFooter, ProjectListSection }
}
</script>
